.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--menu-text-color);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  position: relative;
  background-color: var(--app-background);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
}

/* Wrapper for item list */
.bm-item-list {
}

/* Individual item */
.bm-item {
  margin-bottom: 40px;
}
.bm-item:last-child {
  margin-bottom: 0;
}

.bm-item-wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
}
.bm-item-wrapper svg {
  margin: 0 23px 0 0 ;
}
.bm-item-wrapper a {
  color: var(--menu-text-color);
}

.bm-item-header {
  height: 164px;
  width: 100%;
  padding: 75px 36px;
  background-color: var(--menu-header-color);
  border-bottom: var(--burger-menu-header-bar);
  box-sizing: border-box;
}
.bm-item-header h2 {
  font-size: 16px;
  font-weight: 600px;
  color: var(--menu-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.bm-email-subheading {
  font-size: 12px;
}

.bm-item-inner:first-child {
  margin-left: 34px;
}
.bm-item-inner {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
