.row {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.center {
  justify-content: space-around;
  text-align: center;
}

.form-error {
  font-size: 0.75em;
  color: var(--tomato);
  margin: 2px 0;
}

.grow {
  flex: 1 0 auto;
}

.shrink {
  flex: 0 1 auto;
}

.success {
  color: var(--teal-blue);
}
input[type="button"].success,
input[type="submit"].success,
button.success {
  color: var(--white);
}

.warn {
  color: var(--tomato);
}
input[type="button"].warn,
input[type="submit"].warn,
button.warn {
  color: var(--white);
}

.disabled {
  cursor: not-allowed;
  color: var(--text-color-disabled);
}

.button-small {
  padding: 2px 5px;
  flex: 0 0 auto;
}

.hidden {
  display: none;
}

.PageCTA {
  margin-top: 35px;
  flex: 1 0 auto;
}

.Expander {
  flex: 1 0 auto;
}

.CenterVertical {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.InfoIcon {
  width: 20px;
  height: 20px;
}

.numbering {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  display: block;
  text-align: center;
}
.numbering::before,
.numbering::after {
  content: '\2014';
}

.SubSectionHeading {
  margin-bottom: 0;
}

a.unlink {
  color: var(--text-color);
  font-weight: normal;
}

.react-dropdown-select {
  box-sizing: border-box;
}

.FlatList {
  list-style: none;
  padding: 0;
}

.FlatList li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
}
.FlatList li:last-child {
  margin-bottom: 0;
}

.ButtonHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Settings-Icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--card-icon-color);
}

.recharts-text {
  font-size: 12px;
}

.OutboundLink {
  text-decoration: none;
  font-weight: 600;
  color: var(--link-color);
}

.BottomBorder {
  border-bottom: var(--horizontal-border-bar);
}

.HeaderPrefix {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.94;
  letter-spacing: 0.13px;
  display: block;
}

.ExpansionPanel--Header {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  display: block;
  opacity: 0.7;
}

.ReactMarkdown p {
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  line-height: 1.35rem;
}

.ReactMarkdown ul {
  margin: 0 0 0.5rem 0;
}

.ReactMarkdown ol {
  margin: 0 0 0.5rem 0;
}

.ReactMarkdown li {

}
