.MultipleChoiceQuestionField {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}

.MultipleChoiceQuestionField--text {
  display: block;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: #060417;
  box-sizing: border-box;
  border-bottom: var(--horizontal-border-bar);
  padding-bottom: 22px;
  flex: 0 1 auto;
}

.MultipleChoiceQuestionField--text.error {
  color: red;
}

.MultipleChoiceQuestionField--options {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.MultipleChoiceQuestionAnswerOption {
  display: flex;
  border-bottom: var(--horizontal-border-bar);
}

.MultipleChoiceQuestionAnswerOption {
  padding: 14px 0;
}

.MultipleChoiceQuestionField--radio {
  width: 20px;
  height: 20px;
  opacity: 0.54;
  background-color: #010101;
  margin: 0 18px 0 0;
  flex: 0 0 auto;
}

.MultipleChoiceQuestionField--radio:checked {
  background-color: var(--input-checked);
}
