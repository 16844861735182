:root {
  --primary-blue: #1a57cc;
  --header-dark: #060417;
  --link-color: #3623b7;
  --header-color: #003fbd;
  --app-background: #0547cc;
  --text-color: #5a5a5a;
  --text-color-disabled: #9a9a9a;
  --menu-text-color: #d6e0ff;
  --menu-header-color: #003aad;
  --item-selected-background: #e3e6f0;

  --accent: #f8ba39;
  --dark-grey-blue: #304659;
  --light-periwinkle: #dadef0;
  --very-light-blue: #e1e9f0;
  --cerulean: #0085e3;
  --dark: #2e344d;
  --teal-blue: rgb(0, 168, 157);
  --teal-blue-background: rgba(0, 168, 157, 0.15);
  --light-teal-blue: rgba(0, 168, 157, 0.15);
  --white: #ffffff;
  --pale-grey: #f5f9fc;
  --tomato: #f24024;
  --light-mustard: #ffc659;
  --purpley: #825dde;
  --purpley-blue: #524cc8;
  --bluey-purple: #6b46c6;
  --lightish-blue: #4d6eff;
  --grey: #5c5c5c;
  --light-grey: #6a6a6a;
  --lightest-grey: rgba(0, 0, 0, 0.15);
  --red: red;
  --orange: rgb(220, 80, 39);
  --topaz: #17aba0;

  --input-background: #f1f2f7;
  --input-border: #d4cfff;
  --button-background: linear-gradient(284deg, var(--primary-blue), var(--purpley));
  --button-background-warn: linear-gradient(291deg, var(--tomato) 214%, var(--red) 37%);
  --button-background-small: linear-gradient(291deg, #1a57cc 214%, #6b46c6 37%);
  --button-background-disabled: #c6c6c6;
  --horizontal-border-bar: 0.5px solid rgba(111, 127, 175, 0.19);
  --burger-menu-header-bar: 1px solid rgba(255, 255, 255, 0.23);
  --border-box: solid 1px #ccd1e3;
  --text-color-hint: #6a6a6a;
  --input-unchecked: #010101;
  --input-checked: #0547cc;
  --card-icon-color: rgba(0, 0, 0, 0.3);

  --emotion-aweful: radial-gradient(circle at 52% 100%, #e57373, #b71c1c 95%);
  --emotion-bad: radial-gradient(circle at 52% 100%, #face74, #c79531 95%);
  --emotion-ok: radial-gradient(circle at 52% 100%, #376bd6, #0538a7 95%);
  --emotion-good: radial-gradient(circle at 52% 100%, #138985, #0d474e 95%);
  --emotion-amazing: radial-gradient(circle at 52% 100%, #17aba0, #106869 95%);

  --header-height: 85px;
  --page-padding: 26px;
  --dashboard-section-padding-left: 19px;
  --dashboard-section-padding-right: 19px;
  --dashboard-section-padding-top: 24px;
  --dashboard-section-padding-bottom: 24px;
  --max-content-width: 480px;
  --bottom-sheet-top: 30%;

  --regular-z-index: 1;
  --header-z-index: 10;
  --modal-z-index: 1000;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--header--color);
  overscroll-behavior: none;
  position: relative;
}
body {
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

p {
  margin: 18px 0;
}

a {
  text-decoration: none;
  font-weight: normal;
  color: inherit;
}
a.active {
  color: var(--light-mustard);
}

iframe {
  border: none;
}
